import { Link } from "react-router-dom";
import styled from "styled-components";

import {
	Box,
	EmailSharpIcon,
	Link as MuiLink,
	Typography,
} from "./muiComponents";
import logo from "../../assets/logos/sd-logo.svg";
import instagram from "../../assets/logos/instagram-logo.svg";
import facebook from "../../assets/logos/facebook-logo.svg";
import twitter from "../../assets/logos/twitter-logo.svg";
import youtube from "../../assets/logos/youtube-logo.svg";

const iconStyle = { width: "2rem" };

const textStyle = {
	fontFamily: "Roboto",
	fontSize: "0.6875rem",
	fontWeight: "500",
	lineHeight: "142.188%",
	color: "#fff",
};

const MainFooter = () => {
	return (
		<Container>
			<MainDetails>
				<MainDetailsTop>
					<Box
						sx={{
							height: "3rem",
							width: "3rem",
							display: {
								xs: "none",
								sm: "block",
							},
						}}
					>
						<img src={logo} alt="smart deals logo" height="100%" width="100%" />
					</Box>

					<SocialsIconsContainer>
						<Box
							sx={{ ...iconStyle, width: "3rem", mr: "-.5rem" }}
							component={MuiLink}
							href="https://www.instagram.com/smartdealshub/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={instagram}
								alt="instagram logo"
								height="100%"
								width="100%"
							/>
						</Box>
						<Box
							sx={iconStyle}
							component={MuiLink}
							href="https://www.facebook.com/smartdealsdiscountclub"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={facebook}
								alt="facebook logo"
								height="100%"
								width="100%"
							/>
						</Box>
						<Box
							sx={iconStyle}
							component={MuiLink}
							href="https://twitter.com/smartdealshub"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={twitter}
								alt="twitter logo"
								height="100%"
								width="100%"
							/>
						</Box>
						<Box
							sx={iconStyle}
							component={MuiLink}
							href="https://www.youtube.com/@smartdealshub"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={youtube}
								alt="youtube logo"
								height="100%"
								width="100%"
							/>
						</Box>

						<Box sx={{ ml: "-.3rem" }} component={Link} to="/contact-us">
							<EmailSharpIcon sx={{ color: "#fff", fontSize: "2.3rem" }} />
						</Box>
					</SocialsIconsContainer>
				</MainDetailsTop>

				<MainDetailsBottom>
					<Typography variant="subtitle1" component="p" sx={textStyle}>
						&copy; 2023 SmartDeals. All rights reserved
					</Typography>

					<MainDetailsBottomRight>
						<Typography
							variant="subtitle1"
							component={Link}
							to="/privacy"
							sx={{ ...textStyle, textDecoration: "none", cursor: "pointer" }}
						>
							Privacy policy
						</Typography>
						<Typography
							variant="subtitle1"
							component={Link}
							to="/terms"
							sx={{ ...textStyle, textDecoration: "none", cursor: "pointer" }}
						>
							Terms of use
						</Typography>
					</MainDetailsBottomRight>
				</MainDetailsBottom>
			</MainDetails>

			{/* <TermsModal
				open={openTermsModal}
				handleClose={() => setOpenTermsModal(false)}
				externalSiteUrl="https://docs.smartdeals.com.ng/terms"
			/>
			<TermsModal
				open={openPrivacyPolicyModal}
				handleClose={() => setOpenPrivacyPolicayModal(false)}
				externalSiteUrl="https://docs.smartdeals.com.ng/privacy"
			/> */}
		</Container>
	);
};

export default MainFooter;

const Container = styled.div`
	display: flex;
	justify-content: center;
	background-color: #fce9e9;
	position: sticky;
	top: 100vh;
`;

const MainDetails = styled.div`
	background-color: #0c0c0c;
	width: 100%;
	padding: 2rem;
	width: 100vw;
`;

const MainDetailsTop = styled.div`
	color: rgba(255, 255, 255, 0.7);
	display: flex;
	justify-content: space-between;
	align-items: start;
	border-bottom: 1px solid #fff;
	padding: 0 2rem;
`;

const MainDetailsBottom = styled.div`
	color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 0.5rem;
`;

const MainDetailsBottomRight = styled.div`
	color: #fff;
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const SocialsIconsContainer = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-around;
	padding-top: rem;

	@media (max-width: 594px) {
		flex: 1;
		width: 100%;
	}
`;
