import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Logout from "../../components/common/Logout";
import NotFound from "../pages/NotFoundPage/NotFoundPage";
// import Terms from "../pages/Terms";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Faqs from "../pages/Faqs";
import PartnerFaq from "../pages/PartnerFaq";
// import PartnerTerms from "../pages/PartnerTerms";
// import PartnerPrivacyPolicy from "../pages/PartnerPrivacyPolicy";
import HowItWorks from "../pages/HowItWorks";
import HelpCenter from "../pages/HelpCenter";
import PDFDoc from "../pages/PDFDoc";
import OtherPDFDocs from "../pages/OtherPDFDocs";
import EventForm from "../pages/EventForm";
import EventsFaq from "../pages/EventsFaq";

const BaseRoute = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Navigate to="/help-center" />} />
				<Route path="/logout" element={<Logout />} />
				<Route path="/member-guide" element={<PDFDoc path="member" />} />
				<Route
					path="/terms"
					element={
						<OtherPDFDocs path="member_terms" title="club member terms" />
					}
				/>
				<Route path="/privacy" element={<PrivacyPolicy />} />
				{/* <Route
					path="/privacy"
					element={
						<OtherPDFDocs
							path="member_privacy"
							title="Club Member Privacy Notice"
						/>
					}
				/> */}
				<Route path="/faq" element={<Faqs />} />
				<Route path="/partner_faq" element={<PartnerFaq />} />
				<Route path="/partner-guide" element={<PDFDoc path="partner" />} />
				<Route
					path="/partner_terms"
					element={<OtherPDFDocs path="partner_terms" title="partner terms" />}
				/>
				<Route path="/partner_privacy" element={<PrivacyPolicy />} />
				{/* <Route
					path="/partner_privacy"
					element={
						<OtherPDFDocs
							path="partner_privacy"
							title="Partner Privacy Notice"
						/>
					}
				/> */}
				<Route path="/how-it-works" element={<HowItWorks />} />
				<Route path="/help-center" element={<HelpCenter />} />
				<Route path="/event-guide" element={<PDFDoc path="event" />} />
				<Route
					path="/event_terms"
					element={<OtherPDFDocs path="event_terms" title="event terms" />}
				/>
				<Route path="/event_privacy" element={<PrivacyPolicy />} />
				{/* <Route
					path="/event_privacy"
					element={
						<OtherPDFDocs path="event_privacy" title="Event Privacy Notice" />
					}
				/> */}
				<Route path="/event_faq" element={<EventsFaq />} />
				<Route path="/rsvp" element={<EventForm />} />

				<Route path="*" element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	);
};

export default BaseRoute;
