import styled from "styled-components";

import FooterImages from "./FooterImages";

const Footer = () => {
	return (
		<Container>
			<FooterImages />
		</Container>
	);
};

export default Footer;

const Container = styled.div`
	background-color: #fce9e9;
`;
