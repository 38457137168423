const env = process.env.NODE_ENV || "development";

let cache;

const config = () => {
	if (!cache) {
		cache = Object.freeze({
			env,
			secrets: {
				apiHost:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? "https://core-staging.smartdeals.com.ng"
						: "https://core.smartdeals.com.ng",
				docPDFHost:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? "https://doc-staging.smartdeals.com.ng/files/guide"
						: "https://doc.smartdeals.com.ng/files/guide",
				encryptionPassword:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? process.env.REACT_APP_ENCRYPTION_PASSWORD_DEV
						: process.env.REACT_APP_ENCRYPTION_PASSWORD,
				encryptionSecretKey:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? process.env.REACT_APP_ENCRYPTION_SECRETKEY_DEV
						: process.env.REACT_APP_ENCRYPTION_SECRETKEY,
				homepageClient:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? "https://app-staging.smartdeals.com.ng"
						: "https://app.smartdeals.com.ng",
			},
		});
	}
	return cache;
};

export default config;
