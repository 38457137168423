import request from "../httpService";

export async function getNotFound(region) {
	const url = "/api/notFound";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function fetchFaqs(params) {
	if (!params.keyword) {
		delete params.keyword;
	}
	const url = "/api/customer/getFaq";
	const { data } = await request.get(url, { params });
	return data;
}
