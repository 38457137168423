import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Navbar from "../../components/common/NavBar";
import config from "../../config";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const { docPDFHost } = config().secrets;

const style = {
	padding: "1rem",
	position: "relative",
	height: "100vh",
};

const PDFDoc = ({ path, title }) => {
	const [numPages, setNumPages] = useState();
	const [pageNumber, setPageNumber] = useState(1);

	const theme = useTheme();
    const smallDisplay = useMediaQuery(theme.breakpoints.down("md"));
    
    const filePath = smallDisplay ? path : `${path}_`

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	const handlePreviousPage = () => {
		if (pageNumber > 1) {
			setPageNumber(pageNumber - 1);
		}
	};

	const handleNextPage = () => {
		if (pageNumber < numPages) {
			setPageNumber(pageNumber + 1);
		}
	};

	return (
		<Box>
			<Toolbar />
			<Navbar />
			<Box sx={style}>
				{title && (
					<Typography
						variant="h5"
						gutterBottom
						component="div"
						sx={{
							fontWeight: 700,
							mb: "1rem",
							textAlign: "center",
							textTransform: "capitalize",
						}}
					>
						{title}
					</Typography>
				)}
				<Box>
					<Document
						file={`${docPDFHost}/${filePath}.pdf`}
						onLoadSuccess={onDocumentLoadSuccess}
						renderMode="canvas"
					>
						<Page pageNumber={pageNumber} />
					</Document>
				</Box>
				<Box>
					<Typography variant="body1" textAlign="center" gutterBottom>
						Page {pageNumber} of {numPages}
					</Typography>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							gap: "1rem",
						}}
					>
						<Button
							variant="outlined"
							disabled={pageNumber <= 1}
							onClick={handlePreviousPage}
						>
							Previous
						</Button>
						<Button
							variant="outlined"
							disabled={pageNumber >= numPages}
							onClick={handleNextPage}
						>
							Next
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default PDFDoc;
