import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import CircularProgress from "@mui/material/CircularProgress";

import Navbar from "../../components/common/NavBar";
import request from "../../api/httpService";
import { decryption } from "../../utils/helpers";
import { toast } from "react-toastify";

const style = {
	padding: {
		md: "3rem",
		xs: "1rem",
	},
};

const gridContainerStyle = {
	boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
	borderRadius: "1.5rem",
	padding: {
		xs: "1rem",
		md: "2rem",
	},
	m: "0 auto",
	marginBottom: {
		xs: "1rem",
		md: "2rem",
	},
	width: "30rem",
	maxWidth: "100%",
};

const mainTextStyle = {
	textAlign: "center",
	marginBottom: "1rem",
	textTransform: "capitalize",
};

const buttonStyle = {
	textTransform: "uppercase",
	pt: ".8rem",
	pb: ".8rem",
	mt: "1.5rem",
	fontSize: "1rem",
};

const EventForm = () => {
	const [formData, setFormData] = useState({
		name: "",
		guestNumber: "",
	});
	const [formErrors, setFormErrors] = useState({
		name: "",
		guestNumber: "",
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [searchParams] = useSearchParams();

	const params = searchParams.get("payload");
	const decryptedParams = decryption(decodeURIComponent(params));

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		try {
			e.preventDefault();
			// Validation logic here
			const errors = {};

			if (!formData.name) {
				errors.name = "Name is required";
			}

			if (!formData.guestNumber) {
				errors.guestNumber = "Number of guests is required";
			}

			if (Object.keys(errors).length > 0) {
				setFormErrors(errors);
				return;
			}

			// Your form submission logic here
			setIsSubmitting(true);

			const payload = {
				id: decryptedParams?.id,
				eventId: decryptedParams?.eventId,
				name: formData.name,
				guest: formData.guestNumber,
			};

			const { data } = await request.get("/api/customer/rsvp", {
				params: payload,
			});

			toast.success(data?.message);
			setFormData({
				name: "",
				guestNumber: "",
			});
			setIsSubmitting(false);
		} catch (error) {
			setIsSubmitting(false);
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Internal Server Error");
			} else {
				toast.error("Error", error.message);
			}
		}
	};

	return (
		<div>
			<Toolbar />
			<Navbar />
			<Box sx={style}>
				<Box sx={gridContainerStyle}>
					<Box>
						<Typography variant="h5" component="h2" sx={mainTextStyle}>
							{decryptedParams?.eventName || "Event Form"}
						</Typography>
						<Typography
							variant="body1"
							component="p"
							sx={{ ...mainTextStyle, textAlign: "left" }}
						>
							Dear Guest,
						</Typography>
						<Typography
							variant="body1"
							component="p"
							sx={{
								...mainTextStyle,
								textTransform: "none",
								textAlign: "left",
							}}
						>
							Kindly fill the form below
						</Typography>
					</Box>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={handleSubmit}
					>
						<TextField
							name="name"
							label="Name"
							variant="outlined"
							fullWidth
							value={formData.name}
							onChange={handleChange}
							error={Boolean(formErrors.name)}
							helperText={formErrors.name}
							sx={{ mb: "1rem" }}
						/>
						<TextField
							name="guestNumber"
							label="Number of guests"
							variant="outlined"
							fullWidth
							value={formData.guestNumber}
							onChange={handleChange}
							error={Boolean(formErrors.guestNumber)}
							helperText={formErrors.guestNumber}
							type="number"
							inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
						/>

						<Box>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								sx={buttonStyle}
								fullWidth
								disabled={isSubmitting}
							>
								{isSubmitting ? <CircularProgress size="1.5rem" /> : "RSVP"}
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		</div>
	);
};

export default EventForm;
