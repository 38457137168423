import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";

import Navbar from "../../components/common/NavBar";
import clubIcon from "../../assets/logos/club-icon.svg";
import discountIcon from "../../assets/logos/discount-icon.svg";

const HowItWorks = () => {
	return (
		<Box>
			<Box
				sx={{
					padding: {
						md: "3rem",
						xs: "1rem",
					},
				}}
			>
				<CssBaseline />
				<Navbar />
				<Toolbar />

				<Box>
					<Typography
						variant="h5"
						gutterBottom
						component="div"
						sx={{ fontWeight: 700, mb: "3rem", textAlign: "center" }}
					>
						How it works
					</Typography>

					<Box sx={{ mb: "3rem" }}>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ fontWeight: 700, mb: "1rem" }}
						>
							SMARTDEALS
						</Typography>
						<Typography
							component="div"
							variant="body1"
							sx={{
								mb: "1rem",
							}}
							gutterBottom
						>
							We are Nigeria&apos;s foremost efficient and interactive on-demand
							discount club and deals platform. SmartDeals is a new innovative
							business-to-consumer driven Deals and Discount club solution
							created to enhance how businesses and consumers interact mutually.
						</Typography>
					</Box>

					<Box sx={{ mb: "2rem" }}>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ fontWeight: 700, mb: "1rem" }}
						>
							FLASH DEALS SESSIONS
						</Typography>
						<Typography
							component="div"
							variant="body1"
							sx={{
								mb: "1rem",
							}}
							gutterBottom
						>
							Active SmartDeals discount club members can use accumulated
							SmartDeals points to participate in flash deals sessions.
						</Typography>
						<Typography
							component="div"
							variant="body1"
							sx={{
								mb: "1rem",
							}}
							gutterBottom
						>
							How to participate in a flash deal session?
						</Typography>
						<ol style={{ paddingLeft: "1rem" }}>
							<li>
								<Typography component="div" variant="body1">
									Club members sign up on SmartDeals via web or mobile app.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Club members subscribe to preferred discount club membership
									plan.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Each membership package has free reward points, which
									consumers will use when they participate in flash deal
									sessions.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Club members are notified when an item is activated for a
									flash deal via push notification.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Club members click on the deal button for preferred items and
									required points are deducted per click.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									The deal will run for specified duration e.g 24 hours and the
									club member whose username is displayed on the item as
									“current dealer” when the flash deal is over, earns 90%
									discount off the deal item.
								</Typography>
							</li>
						</ol>
					</Box>

					<Box sx={{ mb: "2rem" }}>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ fontWeight: 700, mb: "1rem" }}
						>
							SMARTDEALS DISCOUNT CLUB SERVICES/OFFERINGS
						</Typography>
						<Typography
							component="div"
							variant="body1"
							sx={{
								mb: "1rem",
								textTrabsform: "capitalize",
							}}
							gutterBottom
						>
							Are you a business owner?
						</Typography>
						<Typography
							component="div"
							variant="body1"
							sx={{
								mb: "1rem",
							}}
							gutterBottom
						>
							Businesses can register at no cost to offer discounts that
							attracts more consumers and as such grow sales revenue.
						</Typography>
						<ol style={{ paddingLeft: "1rem" }}>
							<li>
								<Typography component="div" variant="body1">
									Download “SmartDeals App” on Google and Apple Store.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Sign up to become a discount partner.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Read and accept Terms & Conditions.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Login after account activation.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Provide Business Details.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Provide discount offered (5% - 50%).
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Provide Terms and Conditions for discount e.g Discount
									application to all transactions.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Agree to honor all SmartDeals club members discount claims.
								</Typography>
							</li>
						</ol>
					</Box>

					<Box sx={{ mb: "2rem" }}>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ fontWeight: 700, mb: "1rem" }}
						>
							Want to be a Member?
						</Typography>
						<Typography
							component="div"
							variant="body1"
							sx={{
								mb: "1rem",
							}}
							gutterBottom
						>
							Active SmartDeals club members can access discounts in real time
							at any registered discount partner across the country.
						</Typography>
						<ol style={{ paddingLeft: "1rem" }}>
							<li>
								<Typography component="div" variant="body1">
									Download the “SmartDeals App” from Google and Apple stores.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Sign Up to SmartDeals discount club.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Subscribe to any of our discount club membership plan.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Each membership plan comes with free reward points that are
									used to participate in flash deal sessions.
								</Typography>
							</li>
						</ol>
					</Box>

					<Box sx={{ mb: { md: "3rem", xs: "1rem" } }}>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ fontWeight: 700, mb: "1rem" }}
						>
							How?
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								flexDirection: { md: "row", xs: "column" },
								textAlign: "center",
							}}
						>
							<Box
								sx={{
									width: { md: "30rem", xs: "100%" },
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									mr: {
										md: "1rem",
										xs: 0,
									},
								}}
							>
								<Box sx={{ height: "3.75rem", width: "3.75rem", mb: "1rem" }}>
									<img
										src={clubIcon}
										alt=""
										height="100%"
										width="100%"
										style={{ objectFit: "cover" }}
									/>
								</Box>
								<Typography
									variant="h6"
									gutterBottom
									component="div"
									sx={{ mb: "1rem", textTransform: "uppercase" }}
								>
									club members
								</Typography>
								<Typography component="div" variant="body1">
									Active SmartDeals club members can access discounts in real
									time at any registered discount partner across the country.
								</Typography>
							</Box>

							<Box
								sx={{
									width: { md: "30rem", xs: "100%" },
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Box sx={{ height: "3.75rem", width: "3.75rem", mb: "1rem" }}>
									<img
										src={discountIcon}
										alt=""
										height="100%"
										width="100%"
										style={{ objectFit: "cover" }}
									/>
								</Box>
								<Typography
									variant="h6"
									gutterBottom
									component="div"
									sx={{ mb: "1rem", textTransform: "uppercase" }}
								>
									discount partners
								</Typography>
								<Typography component="div" variant="body1">
									SmartDeals registered discount partners offer discounts on
									products and services to active SmartDeals discount club
									members to promote their everyday lifestyle.
								</Typography>
							</Box>
						</Box>
					</Box>

					<Box
						sx={{
							mb: {
								md: "3rem",
								xs: "1rem",
							},
							backgroundColor: "#E41F2650",
							padding: "1rem",
						}}
					>
						<Typography component="div" variant="body1">
							<span style={{ color: "#E41F26", fontWeight: "bold" }}>
								NOTE:
							</span>{" "}
							To take advantage of SmartDeals discount club offers, simply visit
							any of our registered discount partners&apos; shops or websites,
							patronize them and present your SmartDeals discount App to claim
							your discount.
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default HowItWorks;
