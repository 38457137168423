/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
// import MuiLink from "@mui/material/Link";
// import MenuIcon from "@mui/icons-material/Menu";

// import MobileNavLinks from "../common/MobileNavLinks";
import logo from "../../assets/logos/smart-deals-logo.svg";

const appBarStyles = {
	backgroundColor: "#fff",
	color: "#594e4e",
	zIndex: 1201,
};

export default function ElevateAppBar() {
	const [anchorEl, setAnchorEl] = useState(null);
	// const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

	const isMenuOpen = Boolean(anchorEl);
	// const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	// const handleMobileMenuClose = () => {
	// 	setMobileMoreAnchorEl(null);
	// };

	// const handleMenuClose = () => {
	// 	setAnchorEl(null);
	// 	handleMobileMenuClose();
	// };

	// const handleMobileMenuOpen = (event) => {
	// 	setMobileMoreAnchorEl(event.currentTarget);
	// };

	const menuId = "primary-search-account-menu";
	// const renderMenu = (
	// 	<Menu
	// 		anchorEl={anchorEl}
	// 		anchorOrigin={{
	// 			vertical: "top",
	// 			horizontal: "right",
	// 		}}
	// 		id={menuId}
	// 		keepMounted
	// 		transformOrigin={{
	// 			vertical: "top",
	// 			horizontal: "right",
	// 		}}
	// 		open={isMenuOpen}
	// 		onClose={handleMenuClose}
	// 	>
	// 		<MenuItem onClick={handleMenuClose}>Profile</MenuItem>
	// 		<MenuItem onClick={handleMenuClose}>My account</MenuItem>
	// 	</Menu>
	// );

	// const mobileMenuId = "primary-search-account-menu-mobile";
	// const renderMobileMenu = (
	// 	<Menu
	// 		anchorEl={mobileMoreAnchorEl}
	// 		anchorOrigin={{
	// 			vertical: "top",
	// 			horizontal: "right",
	// 		}}
	// 		id={mobileMenuId}
	// 		keepMounted
	// 		transformOrigin={{
	// 			vertical: "top",
	// 			horizontal: "right",
	// 		}}
	// 		open={isMobileMenuOpen}
	// 		onClose={handleMobileMenuClose}
	// 	>
	// 		{/* <MobileNavLinks /> */}
	// 	</Menu>
	// );

	return (
		<Box>
			<CssBaseline />
			{/* <ElevationScroll {...props}> */}
			<Box sx={{ flexGrow: 1 }}>
				<AppBar sx={appBarStyles} elevation={0}>
					<Toolbar>
						<IconButton
							size="large"
							edge="start"
							color="inherit"
							aria-label="open drawer"
							sx={{ mr: 2 }}
							component={Link}
							to="/"
							disableRipple
						>
							<Box sx={{ height: "2rem" }}>
								<img
									src={logo}
									height="100%"
									width="100%"
									alt="Smart Deals Logo"
								/>
							</Box>
						</IconButton>
						<Box sx={{ flexGrow: 1 }} />
						<Box sx={{ display: "none" }}>
							<Button
								variant="text"
								sx={{ color: "#594E4E", textTransform: "none", mr: "0.5rem" }}
								component={Link}
								to="/login"
							>
								Login
							</Button>

							<Button
								variant="outlined"
								sx={{
									textTransform: "capitalize",
									whiteSpace: "no-wrap",
								}}
								component={Link}
								to="/signup"
							>
								Sign Up
							</Button>
						</Box>
						{/* <Box sx={{ display: { xs: "flex", md: "none" } }}>
							<IconButton
								size="large"
								aria-label="show more"
								aria-controls={mobileMenuId}
								aria-haspopup="true"
								onClick={handleMobileMenuOpen}
								color="inherit"
							>
								<MenuIcon />
							</IconButton>
						</Box> */}
					</Toolbar>
					<Divider />
				</AppBar>
				{/* {renderMobileMenu} */}
				{/* {renderMenu} */}
			</Box>
		</Box>
	);
}
