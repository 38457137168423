import CryptoJS from "crypto-js";

import config from "../config";

const { encryptionPassword, encryptionSecretKey } = config().secrets;

export function encryption(payload) {
	try {
		const stringifiedPayload = JSON.stringify(payload);
		const password = encryptionPassword;
		const secretKey = encryptionSecretKey;

		const hash = CryptoJS.PBKDF2(
			password,
			CryptoJS.enc.Utf16LE.parse(secretKey),
			{
				keySize: 32,
				iterations: 1000,
				hasher: CryptoJS.algo.SHA1,
			}
		).toString(CryptoJS.enc.Hex);

		const key = hash.substring(0, 64);
		const iv = hash.substring(0, 42);

		const encryptionOptions = {
			iv: CryptoJS.enc.Hex.parse(iv),
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		};

		const encrypted = CryptoJS.AES.encrypt(
			CryptoJS.enc.Utf16LE.parse(stringifiedPayload),
			CryptoJS.enc.Hex.parse(key),
			encryptionOptions
		);

		return encrypted.toString();
	} catch (error) {
		console.error(error);
		return "";
	}
}

export function decryption(encryptedData) {
	try {
		const password = encryptionPassword;
		const secretKey = encryptionSecretKey;

		const hash = CryptoJS.PBKDF2(
			password,
			CryptoJS.enc.Utf16LE.parse(secretKey),
			{
				keySize: 256 / 32,
				iterations: 1000,
				hasher: CryptoJS.algo.SHA1,
			}
		).toString(CryptoJS.enc.Hex);

		const key = hash.substring(0, 64);
		const iv = hash.substring(0, 42);

		const encryptionOptions = {
			iv: CryptoJS.enc.Hex.parse(iv),
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		};

		const decrypted = CryptoJS.AES.decrypt(
			encryptedData,
			CryptoJS.enc.Hex.parse(key),
			encryptionOptions
		);
		const stringifiedDecrypted = CryptoJS.enc.Utf16LE.stringify(decrypted);
		// return JSON.parse(CryptoJS.enc.Utf16LE.stringify(decrypted));
		return JSON.parse(stringifiedDecrypted.trim(""));
	} catch (error) {
		console.error(error);
		return {};
	}
}
