import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
// import MuiLink from "@mui/material/Link";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import Navbar from "../../components/common/NavBar";
import { fetchFaqs } from "../../api/common/request";

const style = {
	padding: {
		md: "3rem",
		xs: "1rem",
	},
};

const layoutStyle = {
	maxWidth: "800px",
	margin: "0 auto",
};

const pageTitleStyle = {
	textAlign: "center",
	textTransform: "none",
	fontFamily: "Playfair Display sans-serif",
};

const dividerContainerStyle = {
	textAlign: "center",
	paddingTop: "15px",
	paddingBottom: "15px",
	display: "flex",
	justifyContent: "center",
};

const dividerStyle = {
	width: "80px",
	borderColor: "#000",
	borderBottomWidth: "2px",
};

const accorrdionStyle = {
	backgroundColor: "#f4f4f4",
	mb: ".6rem",
	boxShadow: "none",
	border: "none",
};

const mainAccordionStyle = {
	backgroundColor: "#fff",
	boxShadow: "none",
	border: "none",
	p: "1rem",
};

const accordionSummaryStyle = {
	flexDirection: "row-reverse",
	"& .MuiAccordionSummary-content": {
		marginLeft: "0.5rem",
	},
};

const iconStyle = {
	color: "#000",
};

const accordionMainTextStyle = {
	fontFamily: "Montserrat Roboto sans-serif",
	lineHeight: 1,
	mb: 1,
	whiteSpace: "pre-line",
};

// const listItemStyle = {
// 	marginBottom: ".5rem",
// 	"&:last-child": {
// 		marginBottom: "0",
// 	},
// };

const loaderContainerStyle = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	mt: "2rem",
};

const Faqs = () => {
	const [expanded, setExpanded] = useState(false);
	const [keyword, setKeyword] = useState("");

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const {
		isLoading,
		data = [],
		isError: faqsError,
		error: faqsErrorMessage,
	} = useQuery(
		[`get-faqs-partner-${keyword}`, keyword],
		() => fetchFaqs({ region: "NG", accountType: "partner", keyword }),
		{
			select: (data) => data?.data,
			staleTime: Infinity,
		}
	);

	useEffect(() => {
		if (faqsError) {
			if (faqsErrorMessage.response) {
				toast.error(faqsErrorMessage.response.data?.message);
			} else if (faqsErrorMessage.request) {
				toast.error("Internal Server Error");
			} else {
				toast.error("Error", faqsErrorMessage.message);
			}
		}
	}, [faqsError, faqsErrorMessage]);

	return (
		<Box>
			<Toolbar />
			<Navbar />
			<Box sx={style}>
				<Box sx={layoutStyle}>
					<Box sx={{ mb: "2rem" }}>
						<Typography variant="h3" sx={pageTitleStyle}>
							FAQs
						</Typography>
						<Box sx={dividerContainerStyle}>
							<Divider sx={dividerStyle} />
						</Box>
					</Box>

					<Box sx={{ mb: "2rem" }}>
						<TextField
							value={keyword}
							onChange={(e) => setKeyword(e.target.value)}
							label="Search FAQ"
							size="small"
						/>
					</Box>

					{isLoading && (
						<Box sx={loaderContainerStyle}>
							<CircularProgress />
						</Box>
					)}

					{!isLoading &&
						data?.map((item, index) => (
							<Accordion
								expanded={expanded === `panel${index + 1}`}
								sx={accorrdionStyle}
								onChange={handleChange(`panel${index + 1}`)}
								key={item.id}
							>
								<AccordionSummary
									expandIcon={
										expanded === `panel${index + 1}` ? (
											<RemoveIcon style={iconStyle} />
										) : (
											<AddIcon style={iconStyle} />
										)
									}
									aria-controls={`panel${index + 1}bh-content`}
									id={`panel${index + 1}bh-header`}
									sx={accordionSummaryStyle}
								>
									<Typography>{item?.question}</Typography>
								</AccordionSummary>
								<AccordionDetails sx={mainAccordionStyle}>
									<Typography sx={accordionMainTextStyle} variant="body1">
										{item?.answer}
									</Typography>
								</AccordionDetails>
							</Accordion>
						))}
				</Box>
			</Box>
		</Box>
	);
};

export default Faqs;
