import { QueryClientProvider, QueryClient } from "react-query";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ToastContainer } from "react-toastify";
import BaseRoute from "./views/routes/BaseRoutes";
import customTheme from "./config/themeConfig";
import { ModalProvider } from "./hooks/ModalContext";
import DownloadAppModal from "./components/common/DownloadAppModal";
import "react-toastify/dist/ReactToastify.css";

function App() {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				cacheTime: 0,
			},
		},
	});

	return (
		<div className="App">
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={customTheme}>
					<ModalProvider>
						<CssBaseline />
						<ToastContainer />
						<BaseRoute />
						<DownloadAppModal />
					</ModalProvider>
				</ThemeProvider>
			</QueryClientProvider>
		</div>
	);
}

export default App;
