import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";

import Navbar from "../../components/common/HomeNavbar";
import Footer from "../../components/common/Footer";
import MainFooter from "../../components/common/MainFooter";

const style = {
	padding: {
		md: "3rem",
		xs: "3rem 2rem",
	},
	"&::-webkit-scrollbar": {
		width: "0", // Hide the width of the scrollbar
	},
	"&::-webkit-scrollbar-thumb": {
		background: "transparent", // Hide the scrollbar thumb
	},
};

const layoutStyle = {
	maxWidth: "800px",
	margin: "0 auto",
};

const pageTitleStyle = {
	textAlign: "center",
	textTransform: "uppercase",
	fontFamily: "Playfair Display sans-serif",
};

const dividerContainerStyle = {
	textAlign: "center",
	paddingTop: "15px",
	paddingBottom: "15px",
	display: "flex",
	justifyContent: "center",
};

const dividerStyle = {
	width: "80px",
	borderColor: "#000",
	borderBottomWidth: "2px",
};

const linkTextStyle = {
	color: "inherit !important",
	textDecoration: "none",
};

const HelpCenter = () => {
	return (
		<Box>
			<Toolbar />
			<Navbar />
			<Box sx={style}>
				<Box sx={layoutStyle}>
					<Box sx={{ mb: "2rem" }}>
						<Typography variant="h3" sx={pageTitleStyle}>
							Help Center
						</Typography>
						<Box sx={dividerContainerStyle}>
							<Divider sx={dividerStyle} />
						</Box>
					</Box>

					<Box>
						<ol>
							<li>
								<Typography
									component={Link}
									to="/how-it-works"
									sx={linkTextStyle}
								>
									About SmartDeals
								</Typography>
							</li>
							<li>
								<Typography
									component={Link}
									to="/member-guide"
									sx={linkTextStyle}
								>
									Member User Guide
								</Typography>
							</li>
							<li>
								<Typography component={Link} to="/faq" sx={linkTextStyle}>
									Member FAQ
								</Typography>
							</li>
							<li>
								<Typography component={Link} to="/terms" sx={linkTextStyle}>
									Member Terms & Conditions
								</Typography>
							</li>
							<li>
								<Typography component={Link} to="/privacy" sx={linkTextStyle}>
									Member Privacy Notice
								</Typography>
							</li>
							<li>
								<Typography
									component={Link}
									to="/partner-guide"
									sx={linkTextStyle}
								>
									Discount Partner User Guide
								</Typography>
							</li>
							<li>
								<Typography
									component={Link}
									to="/partner_faq"
									sx={linkTextStyle}
								>
									Discount Partner FAQ
								</Typography>
							</li>
							<li>
								<Typography
									component={Link}
									to="/partner_terms"
									sx={linkTextStyle}
								>
									Discount Partner Terms & Conditions
								</Typography>
							</li>
							<li>
								<Typography
									component={Link}
									to="/partner_privacy"
									sx={linkTextStyle}
								>
									Discount Partner Privacy Notice
								</Typography>
							</li>
							<li>
								<Typography
									component={Link}
									to="/event-guide"
									sx={linkTextStyle}
								>
									Event Manager User Guide
								</Typography>
							</li>
							<li>
								<Typography component={Link} to="/event_faq" sx={linkTextStyle}>
									Event Manager FAQ
								</Typography>
							</li>
							<li>
								<Typography
									component={Link}
									to="/event_terms"
									sx={linkTextStyle}
								>
									Event Manager Terms & Conditions
								</Typography>
							</li>
							<li>
								<Typography
									component={Link}
									to="/event_privacy"
									sx={linkTextStyle}
								>
									Event Manager Privacy Notice
								</Typography>
							</li>
						</ol>
					</Box>
				</Box>
			</Box>
			<Footer />
			<MainFooter />
		</Box>
	);
};

export default HelpCenter;
