import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";

import Navbar from "../../components/common/NavBar";

const style = {
	padding: {
		md: "3rem",
		xs: "1rem",
	},
};

const displayTerms = () => {
	if (window.location.pathname === "/event_privacy") {
		return "/event_terms";
	}
	if (window.location.pathname === "/partner_privacy") {
		return "/partner_terms";
	}
	return "/terms";
};

const PrivacyPolicy = () => {
	return (
		<Box>
			<Toolbar />
			<Navbar />
			<Box sx={style}>
				<Typography
					variant="h5"
					gutterBottom
					component="div"
					sx={{ fontWeight: 700, mb: "3rem", textAlign: "center" }}
				>
					Privacy Notice
				</Typography>

				{/* <Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
					}}
					gutterBottom
				>
					Last updated: June 09, 2022.
				</Typography> */}
				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
						textDecoration: "underline",
						fontWeight: "bold",
					}}
					gutterBottom
				>
					HOW YOU MAY GIVE YOUR CONSENT
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					Please note that the terms of this Privacy Policy apply to your usage
					or continued usage of our services and regulate the acceptance,
					collection, storage, use, transfer, sharing or other forms of
					processing of your information as described herein and in our{" "}
					<Typography
						variant="body1"
						component={Link}
						color="primary"
						to={displayTerms()}
					>
						Terms and Conditions
					</Typography>{" "}
					and by your checking the acceptance box on the sign up page of the
					application, you consent to the application of the terms of this
					Privacy Policy and{" "}
					<Typography
						variant="body1"
						component={Link}
						color="primary"
						to={displayTerms()}
					>
						Terms and Conditions
					</Typography>{" "}
					to your usage of our services.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
						textDecoration: "underline",
						fontWeight: "bold",
					}}
					gutterBottom
				>
					STATEMENT OF COMPLIANCE WITH DATA PROTECTION PRINCIPLES
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					Your personal data is stored securely on our server and we take steps
					to ensure the security of your data by implementing physical,
					electronic, and procedural safeguards in order to protect the
					information, including storage of the information on secured servers
					protected by secured networks to which access is limited to a few
					authorized employees and personnel. Your data is stored for the period
					you are subscribed to our service and if you elect to deactivate your
					account, we delete your data from our server.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
						textDecoration: "underline",
						fontWeight: "bold",
					}}
					gutterBottom
				>
					INFORMATION AVAILABLE TO US
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					When you install, access or use our app, we may process the following
					categories of information for the purposes described below.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
						textDecoration: "underline",
						fontWeight: "bold",
					}}
					gutterBottom
				>
					INFORMATION YOU GIVE TO US
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Account and user profile:
					</Typography>{" "}
					We accept your name, phone number and email address to create your
					user account and profile. You may also choose to provide additional
					information for your user profile such as photo and address.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Address book:
					</Typography>{" "}
					If you have granted consent, by responding to the explicit popup
					request for consent that comes up when you attempt to pick a phone
					number from your address book, we access the contacts in your address
					book. By granting such access, you confirm that you are authorized to
					share the information in your address book with us.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					The contact number you choose from your address book is collected to
					process your service request and will be shared with third-party
					service providers to complete the processing of your initiated service
					request.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Photos:
					</Typography>{" "}
					If you have granted consent, by responding to the explicit popup
					request for consent that comes up when you attempt to upload a profile
					photo or take identity verification photo, we securely store such
					images on our servers. By granting such access, you confirm that you
					are authorized to share the image with us.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Customer support:
					</Typography>{" "}
					When you contact us about your experience of using our services, we
					will use the information you have provided to respond to and resolve
					issues, for example, when you contact us via email support.
				</Typography>

				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
						textDecoration: "underline",
						fontWeight: "bold",
					}}
					gutterBottom
				>
					INFORMATION WE GET WHEN YOU USE OUR SERVICES
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Device and connection information:
					</Typography>{" "}
					We collect your device information and unique identifiers so that you
					can connect to and use our services. Device information means device
					settings, operating system, IP address, and screen resolution.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					We need access to device information to: (a) monitor our systems to
					ensure that they are working as intended; (b) identify and resolve any
					problems and bugs that may only impact one type of manufacturer or
					device type; (c) for security and fraud detection by identifying
					unusual usage patterns; (d) understand whether you have turned on
					notifications and location so that we can comply with your choices.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					Unique identifiers are a number of characters used to identify a
					device. Some are permanent and others can be reset at any time by
					users on their devices. We use unique identifiers for various purposes
					including remembering your preferences, detecting fraud, security,
					customizing services or analyzing device related issues.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Location information:
					</Typography>{" "}
					If you have granted, by responding to the explicit popup request for
					consent that comes up when you attempt to use a location dependent
					feature, we may collect information about your precise device location
					using GPS to enable user features. You can also control whether to
					share your location in your device settings at any time (
					<Typography
						variant="body1"
						component={MuiLink}
						target="_blank"
						href="https://support.google.com/android/answer/6179507"
					>
						Android devices
					</Typography>
					,{" "}
					<Typography
						variant="body1"
						component={MuiLink}
						target="_blank"
						href="https://support.apple.com/en-ng/102647"
					>
						Apple devices
					</Typography>
					).
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Order information:
					</Typography>{" "}
					When you register for paid services, we will collect your payment
					information and payment receipts, including from third parties
					processing such payments. This enables us to keep record of our
					transactions with you.
				</Typography>

				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
						textDecoration: "underline",
						fontWeight: "bold",
					}}
					gutterBottom
				>
					THIRD PARTY INFORMATION
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					We receive information about you from third-parties when you register
					for services or update your profile information using third party
					services such as social media accounts.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					You may also use our services in connection with third-party services.
					In order for the services to interoperate with such third-party
					services we may receive information from them about you or we may
					share your information with them. For example, you may log in to our
					services using third party services or create an account on a
					third-party platform by using our services to pre-populate your
					information to register with them or you may connect your user profile
					with third-party services. By logging in to our app using third party
					services or creating an account on a third-party platform using our
					app or otherwise connecting your user profile on our app with
					third-party services, you agree that we may: (a) collect, accept, use
					and retain the information provided by these third-party services in
					accordance with this Privacy Policy, and (b) supplement your user
					information if, for example, you choose to create your account or
					update your profile information by using Facebook, Google or
					Microsoft. Such third parties only share such information with your
					prior consent and based on their privacy terms. We never store any
					passwords created for third-party services. Your use of third-party
					services is governed by the terms and privacy policies of such
					services.
				</Typography>

				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
						textDecoration: "underline",
						fontWeight: "bold",
					}}
					gutterBottom
				>
					NON-PERSONAL INFORMATION
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Club Members
					</Typography>{" "}
					Some information including usage information and other information
					that may arise from your interaction with SmartDeals app, cannot be
					used to identify you, whether in combination with other information or
					otherwise and will not constitute personal information for the
					purposes of this policy.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Discount Partners
					</Typography>{" "}
					A business may create a profile to make its business information
					(including business or trading name, phone number, email, address,
					business hours and social media handles) publicly available to users
					of the SmartDeals app. Such information will not constitute personal
					information for the purposes of this policy and the{" "}
					<Typography
						variant="body1"
						component={Link}
						color="primary"
						to="/partner_terms"
					>
						Discount Partner Terms
					</Typography>{" "}
					shall apply.
				</Typography>

				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						fontWeight: 700,
						textDecoration: "underline",
					}}
				>
					HOW WE USE PERSONAL INFORMATION
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
					}}
					gutterBottom
				>
					We use the information to provide, operate, maintain, support,
					improve, understand, market and personalize our services for you in
					the following ways.
				</Typography>

				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Services
					</Typography>{" "}
					We use your information to provide and operate our services including:
				</Typography>

				<Box sx={{ pl: "2rem", mb: "1rem" }}>
					<ul>
						<li>
							<Typography variant="body1" component="div">
								Claim discount from our discount partners.
							</Typography>
						</li>
						<li>
							<Typography variant="body1" component="div">
								Purchase airtime and data.
							</Typography>
						</li>
						<li>
							<Typography variant="body1" component="div">
								Make bill payments.
							</Typography>
						</li>
						<li>
							<Typography variant="body1" component="div">
								Create a virtual account.
							</Typography>
						</li>
						<li>
							<Typography variant="body1" component="div">
								Fund and withdraw from your secure wallet.
							</Typography>
						</li>
						<li>
							<Typography variant="body1" component="div">
								Providing customer support, responding to your queries,
								investigating and resolving issues related to the services.
							</Typography>
						</li>
						<li>
							<Typography variant="body1" component="div">
								Improving, fixing, and customizing our services.
							</Typography>
						</li>
						<li>
							<Typography variant="body1" component="div">
								Understanding how our users use our services.
							</Typography>
						</li>
						<li>
							<Typography variant="body1" component="div">
								Analyzing and using the information to evaluate and improve our
								services, business and operations.
							</Typography>
						</li>
						<li>
							<Typography variant="body1" component="div">
								Developing or providing services to third parties or to improve
								third party services.
							</Typography>
						</li>
						<li>
							<Typography variant="body1" component="div">
								Researching, developing and testing new services and features.
							</Typography>
						</li>
					</ul>
				</Box>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Safety and security:
					</Typography>{" "}
					We use your information to comply with applicable laws, identity
					verification, verify user accounts and activity, ensure service
					functionality, safety and data accuracy, investigate suspicious
					activity and enforce our agreements, perform troubleshooting
					activities, and prevent fraud, security breaches, circumvention of the
					intended use of our services, our{" "}
					<Typography
						variant="body1"
						component={Link}
						color="primary"
						to={displayTerms()}
					>
						Terms and Conditions
					</Typography>
					, this Privacy Policy and restricted, prohibited or illegal
					activities.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Third party services:
					</Typography>{" "}
					With your consent, we enable you to use and share your information to
					use our services in connection with other third-party services. For
					example, use payment services. If you use a third-party service via a
					link in our services, you may be providing information directly to
					such third party. In such cases the terms and privacy policies of the
					third-party service will apply to your use of their services.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Third party service providers:
					</Typography>{" "}
					With your consent, we enable you to use and share your information to
					use our services in connection with other third-party services. For
					example, use payment services. If you use a third-party service via a
					link in our services, you may be providing information directly to
					such third party. In such cases the terms and privacy policies of the
					third-party service will apply to your use of their services.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Statistical data:
					</Typography>{" "}
					We use aggregated information for statistical and analytical purposes.
					We may share such data with third parties. We do not consider personal
					information to include information that has been de-identified, made
					anonymous or aggregated so that it can no longer be used to identify a
					specific person, directly, or in combination with other information or
					means that are reasonably likely to be available to us. We may provide
					measurement and analytics services (using such information) in order
					to assist businesses to better understand the effectiveness of their
					user interactions on our services, engagement, and the distribution of
					their products and services.
				</Typography>

				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
						textDecoration: "underline",
						fontWeight: "bold",
					}}
					gutterBottom
				>
					HOW WE ENABLE INFORMATION SHARING
				</Typography>

				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
					}}
					gutterBottom
				>
					We enable information sharing to provide, operate, maintain, support,
					improve, understand or personalize our services, in the following
					ways:
				</Typography>

				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Third party services:
					</Typography>{" "}
					With your consent, we enable you to use and share your information to
					use third party services. For example, use of payment services and
					identity verification using BVN. In such cases the terms and privacy
					policies of the third-party will apply to such information and your
					use of their services.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Third party service providers:
					</Typography>{" "}
					We use trusted third-party service providers to support our services
					and business. When we share information with such third parties, we
					require them to take appropriate organizational and technical measures
					to protect your information and observe applicable laws.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Law and safety:
					</Typography>{" "}
					We may disclose your information if we believe such action is
					necessary to: (a) comply with applicable laws, government requests or
					legal process served on us; (b) protect and defend our rights and the
					enforcement of our agreements including to ensure technical service
					functionality, data accuracy, perform trouble-shooting, protect our
					platform and services from abuse, and prevent or detect security
					breaches and illegal, restricted or prohibited activity; and (c)
					protect the security and safety of users or members of the public or
					other aspects of public importance provided that such disclosure is
					lawful.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						mb: "1rem",
					}}
				>
					<Typography variant="body1" component="span" fontWeight="700">
						Acquisitions and transfer:
					</Typography>{" "}
					We may disclose and transfer your information or our obligations and
					rights under this Privacy Policy to any of our affiliates or
					successors, and to any third party who proposes to acquire all or part
					of business or operations, whether such acquisition is by way of
					investment, merger, restructuring, sale of capital or assets, by
					operation of law, or otherwise.
				</Typography>

				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						fontWeight: "bold",
						textDecoration: "underline",
					}}
				>
					LEGAL BASES AND DATA RETENTION
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
					}}
					gutterBottom
				>
					We collect, use and share your information in accordance with our (for
					example, to deliver services) including based on your consent, usage
					of our services, and the choices you make upon registration and in
					your settings or based on protecting your vital interests or those of
					another natural person or based on the public interest. We ensure that
					processing of data performed for such purposes is necessary for
					protecting vital interests (for example, by ensuring your account can
					only be accessed by one device only until you unlock it for use on
					another device) or performance of tasks carried out in the public
					interest (for example, providing contextual information to detect and
					prevent unlawful activity), and that this outweighs any interest in
					not having the data processed for such purposes. Where we process data
					based only on your consent, you may withdraw your consent at any time
					via your settings. We also collect and process and retain your
					information to comply with applicable laws and our legal obligations.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
					}}
					gutterBottom
				>
					SmartDeals will retain your information for as long as you maintain a
					user profile in the services. You may at any time choose to deactivate
					your account and delete your user profile and information via{" "}
					<Typography
						variant="body1"
						component={MuiLink}
						target="_blank"
						href="https://app.smartdeals.com.ng/delete-account"
					>
						Account Deletion
					</Typography>
					. If you delete your account, your profile information will no longer
					be visible to others in the services, and we delete from our servers
					any of your information that we do not need to provide our services.
					However, deactivating your account will not delete information that
					you have shared with others. We will retain your information for the
					period necessary to fulfill the purposes outlined in our Privacy
					Policy unless a longer retention period is required or permitted by
					law.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						fontWeight: "bold",
						textDecoration: "underline",
					}}
				>
					SECURITY AND TRANSFER OF INFORMATION
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
					}}
					gutterBottom
				>
					The services require by their very nature that your information may be
					transferred to other users and third parties across the globe for
					effective provisioning of the services. SmartDeals may use cloud-based
					services and subcontract storage or processing of your information to
					third parties located in countries other than your home country in
					order to provide the services, such as India and the USA. Information
					emanating from one country may for example, be transferred to and
					processed in another country.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
					}}
					gutterBottom
				>
					We take all reasonable precautions to protect personal information
					from misuse, loss and unauthorized access, including by requiring
					transferees of information located in other countries to comply with
					contractual data protection requirements.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
					}}
					gutterBottom
				>
					We have implemented physical, electronic, and procedural safeguards in
					order to protect the information, including that the information will
					be stored on secured servers and protected by secured networks to
					which access is limited to a few authorized employees and personnel.
					Data exchange between the SmartDeals app and processing servers are
					encrypted. However, no method of transmission over the internet or
					method of electronic storage is 100% secure. In case of any breach,
					your remedy will be as per the{" "}
					<Typography
						variant="body1"
						component={Link}
						color="primary"
						to={displayTerms()}
					>
						Terms and Conditions
					</Typography>{" "}
					. You acknowledge and agree that SmartDeals may transfer your
					information as described above for purposes consistent with this
					policy.
				</Typography>

				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						fontWeight: "bold",
						textDecoration: "underline",
						mb: "1rem",
					}}
				>
					ACCESSING AND CONTROLLING YOUR INFORMATION
				</Typography>

				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						fontWeight: "bold",
						textDecoration: "underline",
					}}
				>
					CHILDREN’S PRIVACY
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
					}}
					gutterBottom
				>
					We do not intentionally or knowingly collect or solicit information
					from children under the relevant age of consent under the laws of
					their respective jurisdictions or knowingly allow such persons to
					register for the services. The services are not directed at children
					and is only intended to be used by individuals who have attained the
					relevant age of consent under the laws of their respective
					jurisdictions.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
					}}
					gutterBottom
				>
					If you believe that we might have information from or about a child
					under the relevant age of consent, please contact us at{" "}
					<Typography
						component={Link}
						href="mailto:dataprotection@makvid.com.ng"
						variant="body1"
						color="primary"
						sx={{
							lineHeight: "1.3rem",
						}}
					>
						dataprotection@makvid.com.ng
					</Typography>
					. We will promptly review the information and take appropriate action
					as required under applicable law.
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						lineHeight: "1.3rem",
						fontWeight: "bold",
						textDecoration: "underline",
					}}
				>
					CHANGES TO THIS PRIVACY POLICY
				</Typography>
				<Typography
					component="div"
					variant="body1"
					sx={{
						mb: "1rem",
						lineHeight: "1.3rem",
					}}
					gutterBottom
				>
					SmartDeals may at any time change this Privacy Policy. SmartDeals will
					notify users of such changes by push notice or via in-app notification
					or by other means. If you object to any changes, you may deactivate
					your account and cease using the services. Your continued use of the
					services after SmartDeals have communicated changes to this Privacy
					Policy, will mean that the collection, use, sharing and processing of
					your personal information is subject to the new Privacy Policy. We may
					from time to time set out more detailed information on how we process
					your personal information in relation to particular services in
					supplemental Privacy Notices which shall be incorporated into and form
					part of this Privacy Policy.
				</Typography>
			</Box>
		</Box>
	);
};

export default PrivacyPolicy;
